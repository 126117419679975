<template>
  <div>
    <Header></Header>
    <div class="bg-gray">
      <div class="container">
        <el-row :gutter="20" justify="center">
          <el-col
            v-for="(item, index) in haveAuthList"
            :key="index"
            :md="8"
            :xs="22"
          >
            <div v-if="item">
              <module :item="item"></module>
              <div v-if="roles[item.key]" class="role">
                角色：{{ roles[item.key].join(" ") }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import module from "@/components/module.vue";
import { onMounted, reactive, toRefs } from "vue";
import { localStore, moduleInfos, _checkToken } from "@/utils/utils.js";
export default {
  components: { Header, module },
  setup() {
    const state = reactive({
      haveAuthList: [],
      info: "",
      roles: {},
      moduleList: [
        "account",
        "yueceping",
        "yuejiao",
        "pindu",
        "iway",
        "primary",
        "junior",
        "jizhen",
        "gaozhen",
        "siou",
        "mingti",
        "tiku",
        "yuejuan",
        "tongji",
        "book",
      ],
    });
    onMounted(() => {
      _checkToken();
      setModule();
    });
    const setModule = () => {
      state.infos = JSON.parse(localStore("userAppRoles"));
      if (state.infos) {
        for (var i = 0; i < state.infos.length; i++) {
          var code = state.infos[i]["appCode"];
          if (!state.roles[code]) {
            state.roles[code] = [];
          }
          state.roles[code][i] = state.infos[i]["roleName"];
        }
      }
      console.log(state.roles);
      var userRoles = {
        r1: "系统管理员",
        r2: "安全保密管理员",
        r3: "安全审计员",
        r5: "学校管理员",
        r6: "教师",
      };
      //如果是以上数据中的角色就这样赋值
      if (userRoles["r" + localStore("userRole")]) {
        state.roles["account"] = [];
        state.roles["account"].push(userRoles["r" + localStore("userRole")]);
      }
      for (let key in state.roles) {
        if (key == "account") {
          state.haveAuthList.push(moduleInfos[key]);
        }
      }
      for (let key in state.roles) {
        if (key == "yueceping") {
          if (moduleInfos[key]) {
            state.haveAuthList.push(moduleInfos[key]);
          }
        }
      }
      //设置module中数据，有权限的系统
      for (let key in state.roles) {
        if (key !== "account" && key !== "yueceping") {
          if (moduleInfos[key]) {
            state.haveAuthList.push(moduleInfos[key]);
          }
        }
      }
      //对haveAuthList重新排序
      let r = [];
      for (var a = 0; a < state.moduleList.length; a++) {
        for (let key in state.haveAuthList) {
          console.log(state.moduleList[a]);
          console.log(state.haveAuthList[key].key);
          if (state.moduleList[a] == state.haveAuthList[key].key) {
            r.push(state.haveAuthList[key]);
          }
        }
      }
      state.haveAuthList = r;
    };
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.container {
  width: 80%;
  margin: 0 auto;
  padding: 40px;
  justify-content: center;
}
.role {
  float: right;
  margin-top: -50px;
  margin-right: 10px;
}
</style>
